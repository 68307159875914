import $ from 'jquery';

function setScrolled($header) {
	if(window.scrollY > 50) {
		$header.addClass('is-scrolled');
	}
	else {
		$header.removeClass('is-scrolled');
	}
}

$(() => {

	const $header = $('#js-header');

	// Add is-scrolled class to header when scrolled past 50 pixels
	setScrolled($header);

	$(window).scroll(() => {
		setScrolled($header);
	});

});
